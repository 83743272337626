.clients-main-block {
	padding-bottom: 30px;
	@media screen and (max-width: $mobile-small) {
		padding-bottom: 10px;
	}
	.fileter-block {
		.select-box {
			width: 146px;
			> span {
				padding: 4px 25px 4px 15px;
				font-size: 18px;
				line-height: 28px;
			}
			ul {
				width: calc(100% + 2px);
    			left: -1px;
				top: 37px;
				li {
					a {
						padding: 6px 15px;
					}
				}
			}
		}
		.btn-block {
			margin-left: 14px;
			@media screen and (max-width:$large-x) {
				margin: 5px 0 0 14px;
			}
			.common-btn-blue {
				min-width: 110px;
				margin-bottom: 0;
				padding: 6px 10px 4px;
				span {
					font-size: 18px;
				    line-height: 28px;
				}
			}
		}
		.search-sort-block {
			@media screen and (max-width:$large-x) {
				margin: 5px 0 0 auto;
			}
			@media screen and (max-width:$ipad-portrait) {
				margin: 10px 0 0 0;
			}
			.auto-search-box {
				@media screen and (max-width: $mobile-small) {
					width: 198px;
				}
				input[type="text"]
				{
					padding: 8px 25px 6px 33px;
					/*@media screen and (max-width: $mobile-small) {
						padding: 5px 9px 5px 35px;
					}*/
				}
			}
			.sort-by {
				.sort-selected {
					h2 {
						font-size: 18px;
						line-height: 28px;
						font-weight: $font-normal;
						padding: 5px 25px;
					}
				}
				.drop-down-menu {
					top: 38px;
				}
			}
		}
		.mobile-show{
			&.add-client {
				display: none;
				margin: 0 0 10px;
				@media screen and (max-width: $mobile-small) {
					display: block;
				}
			}
		}
	}
	.clients-table {
		padding-top: 8px;
		@media screen and (max-width: $mobile-small) {
			padding: 0 23px;
		}
		&.table-outer {
			.table-responsive {
				table {
					thead {
						tr {
							th {
								@media screen and (max-width: $large-mx) {
									font-size: 16px;
									line-height: 22px;
								}
								&:nth-child(1) {
									@media screen and (max-width: $large-mx) {
										width: 30%;
									}
									@media screen and (max-width: $large) {
										width: 410px;
									}
								}

								&:nth-child(2) {
									@media screen and (max-width: $large-mx) {
										width: 25%;
									}
									@media screen and (max-width: $large) {
										width: 435px;
									}
								}

								&:nth-child(3) {
									@media screen and (max-width: $large-mx) {
										width: 25%;
									}
									@media screen and (max-width: $large) {
										width: 340px;
									}
								}

								&:nth-child(4) {
									@media screen and (max-width: $large-mx) {
										width: 20%;
									}
									@media screen and (max-width: $large) {
										width: 150px;
									}
								}
							}
						}
					}
					tbody {
						tr {
							td {
								@media screen and (max-width: $large-mx) {
									font-size: 16px;
									line-height: 20px;
								}
								&:nth-child(1) {
									h3 {
										@media screen and (max-width: $large-mx) {
											font-size: 16px;
											line-height: 20px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}


/*Checkboxcustom*/
.checkbox-cust {
	label {
		width: 18px;
		height: 18px;
		border: 1px solid $dim-gray;
		border-radius: 2px;
		padding: 0;
		display: inline-block;
		overflow: hidden;
		position: relative;
		&:before {
			content: "";
			display: inline-block;
			width: 12px;
			height: 12px;
			background-image: url(#{$imgpath}/ic_right.svg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: right center;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			-webkit-transform: translate(-50%,-50%);
			opacity: 0;
			visibility: hidden;
		}
	}
	input[type="checkbox"] 
	{
		visibility: hidden;
		position: absolute;
		opacity: 0;
		&:checked {
			+ label {
				&:before {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

}




