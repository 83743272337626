.reps-main-block {
	@media screen and (max-width:$mobile-small) {
		padding: 0 23px;
	}

	.fileter-block {
		.select-box {
			max-width: 146px;

			span {
				font-size: 18px;
				line-height: 28px;
				padding: 4px 15px;
			}

			.list {
				top: 37px;
				width: calc(100% + 2px);
				left: -1px;
			}

			&:hover {
				.list {
					box-shadow: 0 12px 12px rgba(69, 91, 99, 0.08);
				}
			}
		}

		.btn-block {
			margin-left: 14px;

			.common-btn-blue {
				min-width: 110px;
				margin-bottom: 0;
				padding: 6px 10px 4px;

				span {
					font-size: 18px;
					line-height: 28px;
				}

				&:after {
					background-size: contain;
					background-repeat: repeat-x;
				}
			}

			@media screen and (max-width:$mobile-small) {
				margin: 0 0 15px 0;
			}
		}

		.search-sort-block {
			.auto-search-box {
				width: 238px;

				form {
					input[type="text"] {
						padding: 8px 15px 6px 33px;
					}
				}
			}

			.sort-by {
				width: 180px;

				h2 {
					font-size: 18px;
					line-height: 28px;
					padding: 5px 25px;
				}

				.drop-down-menu {
					top: 38px;
				}
			}
		}

		@media screen and (max-width:$mobile-small) {
			padding: 0 0 10px 0;
		}
	}

	.reps-table {
		.table-responsive {
			.table {
				thead {
					tr {
						th {
							&:nth-child(1) {
								width: 395px;

								@media screen and (max-width:$large-mx) {
									width: 30%;
								}

								@media screen and (max-width:$large) {
									width: 410px;
								}
							}

							&:nth-child(2) {
								@media screen and (max-width:$large-mx) {
									width: 25%;
								}

								@media screen and (max-width:$large) {
									width: 435px;
								}
							}

							&:nth-child(3) {
								@media screen and (max-width:$large-mx) {
									width: 25%;
								}

								@media screen and (max-width:$large) {
									width: 340px;
								}
							}

							&:nth-child(4) {
								@media screen and (max-width:$large-mx) {
									width: 20%;
								}

								@media screen and (max-width:$large) {
									width: 150px;
								}
							}
						}

						&:first-child {
							td {
								padding-top: 40px;
							}
						}
					}
				}

				tbody {
					tr {
						&:nth-child(1) {
							td {
								padding-top: 35px;

								@media screen and (max-width: $mobile-small) {
									padding-top: 20px;
								}
							}
						}

						td {
							vertical-align: middle;
							font-size: 16px;
							line-height: 20px;
							color: $light-black;
							.name-edit {
								.right-detail {
									h3 {
										display: inline-block;
										margin-bottom: 0;
										font-size: 16px;
										line-height: 20px;
										color: $light-black;
									}

									.action {
										@media screen and (max-width:$ipad-landscape) {
											display: block;
										}
										a {
											padding-right: 16px;
											font-size: 16px;
										line-height: 22px;
											&:after {
											    right: 8px;
												height: 16px;
											}
										}
									}
								}
							}
						}
						&:hover {
							.name-edit {
								.right-detail {
									.action {
										display: block;
									}
								}
							}
						}
					}

				}
			}
		}
	}

}

.right-content-part {
	.bottom-content-block {
		&.reps-filter {
			padding-top: 30px;

			.border-bottom {
				border: none;
				padding-bottom: 20px;

				@media screen and (max-width:$mobile-small) {
					padding-bottom: 10px;
				}

				&:after {
					display: none;
				}
			}
		}
	}
}