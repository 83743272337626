.password-chnge-block {
	padding: 50px 0 0;
	@media screen and (max-width:$mobile-small) {
		padding: 20px 0 0;
	}
	form {
		max-width: 400px;
    	width: 100%;
		.form-group{
			margin-bottom:  12px;
		}
		@media screen and (max-width:$mobile-small) {
			padding: 0 12px;
		}
	}
}