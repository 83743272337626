.rep-contacts-main {
	@media screen and (max-width:$mobile-small) {
		padding: 0 16px;
	}
	align-items: flex-start;
	.rep-contracts-left {
		max-width: 60%;
		width: 100%;
/*
		@media screen and (max-width:$large-mx) {
			max-width: none;
			width: calc(100% - 350px);
		}*/
		@media screen and (max-width: $ipad-landscape) {
			width: 100%;
			max-width: none;
		}
		.get-in-touch {
			margin-top: 42px;

			@media screen and (max-width: $ipad-landscape) {
				margin-top: 15px;
			}

			h5 {
				margin: 0 0 10px;
				font-size: 22px;
				line-height: 26px;
				color: $black;
				font-family: $gibson;
				font-weight: $font-semi-bold;

				@media screen and (max-width: $ipad-landscape) {
					font-size: 16px;
					line-height: 28px;
					margin: 0 0 5px;
				}
			}

			form {
				textarea {
					border: 1px solid $gray-dark-border;
					box-shadow: 0px 3px 6px $gray-dark-shadow;
					min-height: 316px;
					resize: none;
					padding: 22px 30px;
					font-size: 16px;
					line-height: 24px;
					color: $light-black;
					font-family: $arial;
					font-weight: $font-normal;
					font-style: italic;

					@media screen and (max-width: $ipad-landscape) {
						min-height: 190px;
						font-size: 14px;
						padding: 13px 15px;
						border-radius: 5px;
					}
				}

				.btn-block {
					padding-top: 30px;

					@media screen and (max-width: $ipad-landscape) {
						padding-top: 13px;
					}

					button {
						min-width: 244px;
						margin-bottom: 0;

						@media screen and (max-width:$mobile-small) {
							min-width: 156px;
							padding: 5px 10px 3px;
						}
						@media (max-height: $mob-landscape-375) and (orientation: landscape) {
							min-width: 156px;
							padding: 5px 10px 3px;
						}

						&:after {
							background-repeat: repeat-x;
							background-size: contain;
							width: 100%;
						}
					}
				}
			}
		}
	}

	.rep-contracts-right {
		max-width: calc(40% - 30px);
		width: 100%;
		border: 1px solid $off-white;
		padding: 27px 30px;
		margin-left: 30px;

		/*	@media screen and (max-width:$large-mx) {
			margin-left: 20px;
			padding: 15px;
		}*/
		@media screen and (max-width: $ipad-landscape) {
			width: 100%;
			max-width: 350px;
			margin: 25px 0 0 0;
		}
		@media (max-height: $mob-landscape-375) and (orientation: landscape) {
			display: none;
		}
		

		h6 {
			margin: 0;
			font-size: 18px;
			line-height: 22px;
			padding-bottom: 5px;
			color: $black;
			font-family: $arial;
			font-weight: $font-normal;
			border-bottom: 2px solid $light-black-2;
		}

		ul {
			padding-left: 0;
			padding-top: 10px;

			li {
				a {
					text-decoration: none;
					display: inherit;
					align-items: center;

					img {
						display: inline-block;
						vertical-align: middle;
						max-width: 19px;
					}

					span {
						font-size: 16px;
						line-height: 20px;
						padding-left: 8px;
						display: inline-block;
						vertical-align: middle;
						color: $light-black;
						font-family: $arial;
						font-weight: $font-normal;
						@include transition(0.3s all ease-in-out);
					}
				}

				&:hover {
					span {
						color: $sky-blue;
					}
				}

				&:not(:last-child) {
					margin-bottom: 12px;
				}
				&:nth-child(2) {
					a {
						span {
							padding-left: 15px;
						}
					}
				}
			}
		}
	}
}


/*Personal detail box */
.person-detils-box {
	padding: 40px 44px;
	position: relative;

	@media screen and (max-width:$large-mx) {
		padding: 30px 15px 30px 30px;
	}

	@media screen and (max-width:$mobile-small) {
		padding: 17px;
	}

	.person-img {
		max-width: 195px;
		width: 100%;

		@media screen and (max-width:$large-mx) {
			max-width: 140px;
		}

		@media screen and (max-width:$large-m) {
			max-width: 98px;
		}

		img {
			    width: 160px;
				height: 160px;
				border-radius: 50%;
				overflow: hidden;

			@media screen and (max-width: $ipad-landscape) {
				width: 156px;
				height: 156px;
			}
			@media screen and (max-width:$large-mx) {
				width: 140px;
				height: 140px;
			}

			@media screen and (max-width:$large-m) {
				width: 98px;
				height: 98px;
			}
		}
	}

	.no-client-details {
		display: inherit;
		align-items: center;
		padding-left: 15px;
		font-size: 18px;
		color: $light-black;
		font-family: $gibson;
		font-weight: $font-semi-bold;
	}

	.person-right {
		margin-top: -6px;
		@media screen and (max-width:$large-mx) {
			width: calc(100% - 145px);
			padding-left: 10px;
		}
		@media screen and (max-width:$large) {
			width: calc(100% - 100px);
		}
		@media screen and (max-width:$mobile-small) {
			width: 100%;
			padding-left: 0;
			padding-top: 17px;
			margin-top: 17px;
			border-top: 2px solid $white;
		}
		
		@media (max-height: $mob-landscape-375) and (orientation: landscape) {
				width: 100%;
			padding-left: 0;
			padding-top: 17px;
			margin-top: 17px;
			border-top: 2px solid $white;
		}

		.person-title {
			h3 {
				margin: 0;
				font-size: 40px;
				line-height: 46px;
				color: $cobalt-blue;
				font-family: $gibson;
				font-weight: $font-semi-bold;
				word-break: break-word;
				@media screen and (max-width:$large-m) {
					font-size: 30px;
					line-height: 36px;
				}

				@media screen and (max-width:$mobile-small) {
					font-size: 16px;
					line-height: normal;
				}
				@media (max-height: $mob-landscape-375) and (orientation: landscape) {
					font-size: 16px;
					line-height: normal;
				}
			}

			h4 {
				margin: 8px 0 26px 0;
				font-size: 18px;
				line-height: 22px;
				color: $black;
				font-family: $arial;
				font-weight: $font-normal;
				word-break: break-word;
				@media screen and (max-width:$mobile-small) {
					font-size: 14px;
					margin: 0;
				}
				@media (max-height: $mob-landscape-375) and (orientation: landscape) {
					font-size: 14px;
					margin: 0;
				}
			}

			ul {
				align-items: center;
				padding-left: 0;
				margin: 0 0 10px 0;

				@media screen and (max-width:$mobile-small) {
					margin: 0 0 5px;
				}
				@media (max-height: $mob-landscape-375) and (orientation: landscape) {
					display: flex;
				}

				li {
					&:not(:last-child) {
						margin-right: 18px;
					}

					img {
						max-width: 20px;
					}
				}
			}

			@media screen and (max-width:$mobile-small) {
				display: inline-block;
				position: absolute;
				top: 35px;
				left: 140px;
				width: calc(100% - 150px);
			}
			@media (max-height: $mob-landscape-375) and (orientation: landscape) {
				display: inline-block;
				position: absolute;
				top: 35px;
				left: 140px;
			}
			@media (max-height: $mob-landscape-375) and (orientation: landscape) {
				display: inline-block;
				position: absolute;
				top: 35px;
				left: 140px;
			}
			@media (max-height: $mob-landscape-375) and (width: $iphone-x) and (orientation: landscape) {
				top: 42px;
			}
			
			
		}

		.person-details {
			.bottom-details {
				.left {
					align-items: flex-start;
					max-width: 176px;
					@media screen and (max-width:$mobile-small) {
						max-width: none;
					}
					img {
						width: 16px;
						height: auto;
					}
					span {
						font-size: 16px;
						line-height: 20px;
						padding-left: 11px;
						color: $light-black;
						font-family: $arial;
						font-weight: $font-normal;
						width: calc(100% - 20px);

						@media screen and (max-width:$large-m) {
							font-size: 14px;
						}

						> br {
							@media screen and (max-width:$mobile-small) {
								display: none;
							}
						}
					}
				}

				.right {
					padding-left: 35px;

					@media screen and (max-width:$large-mx) {
						padding-left: 15px;
					}

					@media screen and (max-width:$ipad-portrait) {
						padding-left: 0;
					}

					ul {
						margin: 5px 0 0 0;

						@media screen and (max-width:$large-m) {
							padding-left: 5px;
							margin-top: 0;
						}

						@media screen and (max-width:$mobile-small) {
							padding-left: 0;
							margin-top: 7px;
						}

						li {
							&:not(:last-child) {
								margin-bottom: 16px;

								@media screen and (max-width:$large-m) {
									margin-bottom: 0;
								}
							}
							
							a {
								text-decoration: none;
								display: flex;
								flex-wrap: wrap;
								align-items: center;

								span {
									font-size: 16px;
									line-height: 20px;
									margin-left: 12px;
									color: $light-black;
									font-family: $arial;
									font-weight: $font-normal;
									strong {
										padding-right: 3px;
									}
									@include transition(0.3s all ease-in-out);
									@media screen and (max-width:$large-m) {
										font-size: 14px;
										line-height: 34px;
									}
								}

								&:hover {
									span {
										color: $sky-blue;
									}
								}
							}
							&:nth-child(1) {
								a {
									img {
										width: 18px;
									}
								}
							}
							&:nth-child(2) {
								a {
									img {
										width: 20px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}