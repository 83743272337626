.admin-products-add {
	padding-top: 25px;
	@media screen and (max-width:$ipad-portrait) {
		padding-top: 15px;
	}
	@media screen and (max-width:$mobile-small) {
		padding: 15px 23px 0;
	}
	form {
		width: 100%;
		.form-group {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 15px;
			max-width: 660px;
			width: 100%;
			.input-box {
				max-width: 481px;
				width: 100%;
				input {
					width: 100%;
					border: 1px solid $dim-gray;
					background-color: $white;
					&:focus {
						border-color: $sky-blue;
					}
				}
				.empty-field {
					display: block;
					margin-top: 3px;
				}
			}
			label {
				font-size: 18px;
				line-height: 22px;
				color: $black;
				font-family: $arial;
				font-weight: $font-normal;
				display: inherit;
				width: 179px;
				padding-right: 15px;
				margin-bottom: 0;
				@media screen and (max-width:$ipad-portrait) {
					margin-bottom: 3px;
				}
				@media screen and (max-width:$mobile-small) {
					padding: 0 0 5px;
					font-size: 16px;
					line-height: 20px;
				}
				@media (max-height: $mob-landscape-414) and (orientation: landscape) {
					padding: 0 0 5px;
					font-size: 16px;
					line-height: 20px;
				}
			}

		}
		.upload-thumbnail {
			padding: 8px 0 28px;

			.upload-thumbnail-right {
				height: 269px;
				max-width: 481px;
				width: 100%;
				margin-left: 10px;
				position: relative;

				.loader {
					position: absolute;
				}

				@media screen and (max-width:$ipad-portrait) {
					margin-left: 0;
					margin-top: 15px;
					height: auto;
					padding-bottom: 56.25%;
				}

				.upload-thumbnail-img {
					position: absolute;
					height: 100%;
					width: 100%;
					background-size: contain;
				}
			}

			.upload-btn-block {
				width: 172px;

				@media screen and (max-width:$ipad-portrait) {
					width: 100%;
				}

				.btn {
					min-width: 172px;

					span {
						font-size: 18px;
						line-height: 28px;
						@media (max-height: $mob-landscape-414) and (orientation: landscape) {
							font-size: 16px;
						}

						@media screen and (max-width:$mobile-small) {
							font-size: 16px;
						}
					}
				}

				@media (max-height: $mob-landscape-414) and (orientation: landscape) {
					width: 100%;
				}

				> .empty-field {
					margin-top: 3px;
					display: block;
					width: 100%;
				}
				.suggestion-file-name {
					font-size: 16px;
				}
			}
		}

		.product-sheet-title {
			padding: 39px 0 28px;

			@media screen and (max-width:$ipad-portrait) {
				padding: 20px 0;
			}

			border-top: 1px solid $off-white;
			border-bottom: 1px solid $off-white;

			.upload-prod-sheet {
				margin-left: 179px;
				margin-bottom: 25px;
				margin-top: 25px;

				@media screen and (max-width:$ipad-landscape) {
					margin-bottom: 0;
				}

				@media screen and (max-width:$ipad-portrait) {
					margin: 15px 0 0 auto;
				}
				
				.upload-btn-block {
					
				.btn {
					min-width: 255px;

					@media screen and (max-width:$ipad-portrait) {
						min-width: auto;
					}

					span {
						font-size: 18px;
						line-height: 28px;
						@media (max-height: $mob-landscape-414) and (orientation: landscape) {
							font-size: 16px;
						}

						@media screen and (max-width:$mobile-small) {
							font-size: 16px;
						}
					}

					&:after {
						height: 110px;
						top: 50px;
					}
				}
				.document-item {
					@media screen and (max-width:$mobile-small) {
						width: 100%; 
						display: block;
						margin: 5px 0;
					}
				}	
				.empty-field { display: block; width: 100%; margin-top: 3px; }	
					
				}	
				
			}
		}
		.btn-block {
			padding-top: 32px;
			align-items: center;
			display: flex;
			@media screen and (max-width:$mobile-small) {
				padding-top: 25px;
			}
			.btn {
				margin-bottom: 0;
				min-width: 196px;

				@media screen and (max-width:$mobile-small) {
					min-width: auto;
				}

				span {
					font-size: 20px;
					line-height: 46px;
					
					@media (max-height: $mob-landscape-414) and (orientation: landscape) {
							font-size: 16px;
							line-height: 36px;
						}

						@media screen and (max-width:$mobile-small) {
							font-size: 16px;
							line-height: 36px;
						}
				}
			}
		}
	}
}