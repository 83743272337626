.your-product-list {
	max-width: none;
	width: 100%;
	margin-top: 18px;
	@media screen and (max-width:$large-mx) {
		padding-right: 15px;
		margin-top: 0;
	}
	@media screen and (max-width:$mobile-small) {
		padding: 0 23px;
	}
	.your-product-box
	{
		box-shadow: 0px 4px 16px $product-box-shadow;
		margin-top: 30px;
		position: relative;
		@media screen and (max-width:$mobile-small) {
			margin-top: 14px;
		}
		.product-image
		{
			width: 265px;
			padding-bottom: 13.94%;
			box-shadow: 0px 4px 16px $gray-light;
			@media screen and (max-width:$ipad-portrait) {
				width: 100%;
				padding-bottom: 73.94%;
			}
			@media screen and (max-width:$mobile-small) {
				padding-bottom: 52.70%;
			}
		}
		.product-content
		{
			padding: 30px 90px 20px 43px;
			width: calc(100% - 265px);
			@media screen and (max-width:$large-m) {
				padding: 30px 100px 20px 20px;
			}
			@media screen and (max-width:$ipad-portrait) {
				width: 100%;
			}
			@media screen and (max-width:$mobile-small) {
				padding: 15px 80px 20px 16px;
			}
			> a {
				font-size: 22px;
				line-height: 28px;
				color: $cobalt-blue;
				font-family: $gibson;
				font-weight: $font-semi-bold;
				margin-bottom: 8px;
				text-decoration: none;
				@include transition(all 0.3s ease-in-out);
				@media screen and (max-width:$mobile-small) {
					font-size: 16px;
				}
				&:hover {
					color: $sky-blue;
				}
			}
			h4 {
				font-size: 18px;
				line-height: 22px;
				color: $black;
				font-family: $arial;
				font-weight: $font-normal;
				margin: 6px 0 5px 0;
				position: relative;
				> span {
					padding-right: 20px;
					display: inline-block;
					position: relative;
					&:not(:last-child):after 
					{
						content: "";
						display: block;
						width: 2px;
						height: 18px;
						background-color: $black;
						position: absolute;
						right: 7px;
						top: 50%;
						transform: translateY(-50%);
						-webkit-transform: translateY(-50%);
					}
				}
				@media screen and (max-width:$mobile-small) {
					font-size: 14px;
					margin-top: 0;
				}
			}
			p {
				font-size: 16px;
				line-height: 20px;
				color: $light-black;
				font-family: $arial;
				font-weight: $font-normal;
				margin: 0;
				max-width: 652px;
				width: 100%;
				@media screen and (max-width:$mobile-small) {
					display: none;
				}
			}
			.purchase-date {
				font-size: 14px;
				line-height: 18px;
				color: $panton-gray;
				font-family: $arial;
				font-weight: $font-normal;
				padding-top: 13px;
				@media screen and (max-width:$mobile-small) {
					font-size: 12px;
					padding-top: 8px;
				}
			}
		}
		.btn-block
		{
			position: absolute;
			bottom: 25px;
			right: 32px;
			@media screen and (max-width:$large-m) {
				right: 20px;
			}
			@media screen and (max-width:$mobile-small) {
				right: 12px;
    			bottom: 20px;
			}
			a {
				text-decoration: none;
				display: inline-block;
				&:not(:last-child) {
					margin-right: 5px;
				}
				img, svg 
				{
					@include transition(all 0.5s ease-in-out);
					width: 54px;
					height: 54px;
					@media screen and (max-width:$large-mx) {
						width: 33px;
						height: 33px;
					}
				}
				&:hover {
					svg {
						.shp0{
							fill:$cobalt-blue;
						}
					}
				}
			}
		}
	}
}



