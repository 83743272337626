.admin-profile {
	.top-heading {
		position: relative;
		z-index: 1;
		background: none;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			opacity: 0.20;
			background: rgb(255,255,255);
			background: linear-gradient(90deg, $off-white 35%, $sky-blue-gradiant 100%);
		}
	}
	.profile-left-content {
		.your-profile-info {
			h2 {
				font-size: 22px;
				line-height: 46px;
				font-family: $gibson;
				font-weight: $font-semi-bold;
				margin-bottom: 0;
			}
		}
		.profile-form-block {
			padding-right: 10px;
			.upload-profile-photo {
				.prof-user-img {
					height: 130px;
					width: 130px;
					border-radius: 50%;
					display: inline-block;
					@media screen and (max-width:$ipad-landscape) {
						height: 110px;
						width: 110px;
					}
					@media screen and (max-width:$mobile-small) {
						height: 96px;
						width: 96px;
					}
				}
			}
		}
		form {
			input[type="text"], input[type="email"], select {
				background-color: $off-white;
			}
		}
		
	}
}


.profile-cust {
	nav {
		&.fixed-left
		{
			@media screen and (max-width:$mobile-small) {
				justify-content: space-around;
				display: flex;
				align-items: center;
			}
			.navbar-logo {
				@media screen and (max-width:$mobile-small) {
					display: block;
					margin: 0;	
					width: 100px;
				}
			}
			ul {
				li {
					padding: 0 10px;
				}
			}
		}
	}
}




