.news-main
{
	.news-feed-left
	{
		width: 60.96%;
		@media screen and (max-width:$ipad-landscape) {
			width: 100%;
			@media screen and (max-width:$mobile-small) {
				padding: 0 11px;
			}
		}
		.news-title
		{
			padding: 44px 0 22px 50px;
			position: relative;
			margin-bottom: 10px;
			@media screen and (max-width:$large-m) {
				padding: 30px 0 30px 30px;
			}
			@media screen and (max-width:$mobile-small) {
					padding: 20px 0 20px 30px;
			}
			img {
				width: 27px;
				@media screen and (max-width:$mobile-small) {
					width: 20px;
					position: absolute;
					right: 20px;
				}
			}
			h3
			{
				color: $white;
				font-size: 22px;
				line-height: 26px;
				font-family: $gibson;
				font-weight: $font-semi-bold;
				margin-top: 16px;
    			margin-bottom: 0;
				max-width: calc(100% - 320px);
				@media screen and (max-width:$large-mx) {
					max-width: calc(100% - 230px);
				}
				@media screen and (max-width:$mobile-small) {
					font-size: 16px;
					line-height: 28px;	
					margin-top: 0;
					max-width: none;
				}
			}
			p{
				color: $white;
				font-size: 16px;
				line-height: 20px;
				font-family: $arial;
				font-weight: $font-normal;
    			width: 61.38%;
				margin-top: 8px;
				@media screen and (max-width:$large-m) {
					width: 66.38%;
				}
				@media screen and (max-width:$mobile-small) {
					font-size: 15px;
					line-height: 20px;
					width: 92.38%;
					margin-bottom: 0;
				}
			}
			.time-date {
				font-size: 14px;
				line-height: 24px;
				color: $off-white;
				font-family: $arial;
				font-weight: $font-normal;
				@media screen and (max-width:$mobile-small) {
					font-size: 12px;
				}
			}
		}
		
		.news-date
		{
			text-align: center;
			position: relative;
			margin-bottom: 10px;
			h4 {
				font-size: 16px;
				line-height: 18px;
				font-family: $gibson;
				font-weight: $font-semi-bold;
				color: $panton-gray;
				position: relative;
				padding: 0 25px;
				text-align: center;
				display: inline-block;
				background-color: $white;
				position: relative;
				z-index: 1;
				@media screen and (max-width:$mobile-small) {
					font-size: 14px;
					line-height: 16px;
				}
			}
			&:after 
			{	
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				background-color: $panton-gray;
				position: absolute;
				top: 30px;
				z-index: 0;
			}
		}
		svg {
				position: absolute;
				right: -30px;
				bottom: -10px;
				width: 328px;
				top: auto;
				max-height: 91%;
				height: 230px;
			@media screen and (max-width:$large-mx) {
					right: -6px;
					bottom: -10px;
					width: 228px;
					top: auto;
					height: 160px;
			}
			@media screen and (max-width:$mobile-small) {
				    width: 186px;
					bottom: 0;
					opacity: 18%;
					height: 129px;
			}
		}
		.datewise-common-block
		{
			padding: 46px 50px 28px 96px; 
			@media screen and (max-width:$large-m) {
				padding: 30px 30px 28px 50px;
    			margin-bottom: 30px;
			}
			@media screen and (max-width:$mobile-small) {
				padding: 22px 29px 20px 22px;
			}
			margin-bottom: 45px;
			.top-title
			{
				position: relative;
				> img {
					position: absolute;
					left: -45px;
					top: 0;
					width: 27px;	
					@media screen and (max-width:$large-m) {
						min-width: auto;
						width: 23px;
						left: -35px;
						top: 0;
					}
					@media screen and (max-width:$mobile-small) {
						right: -10px;
    					left: auto;
						width: 21px;
					}
				}
				h4 {
					font-size: 22px;
					line-height: 26px;
					margin: 0;
					color: $black;
					font-family: $gibson;
					font-weight: $font-semi-bold;
					span {
						position: relative;
						padding-right: 32px;
						display: inline-block;
						@media screen and (max-width:$mobile-small) {
							padding-right: 12px;
						}
						&:after {
							content: "";
							height: 20;
							width: 3px;
							background-color: $black;
							display: inline-block;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							-webkit-transform: translateY(-50%);
							right: 4px;			
						}
					}
					@media screen and (max-width:$mobile-small) {
						font-size: 16px;
						line-height: 18px;
					    padding-right: 10px;	
					}
				}
			}
			.time-date
			{
				font-size: 14px;
				line-height: 24px;
				color: $panton-gray;
				font-family: $arial;
				font-weight: $font-normal;
				@media screen and (max-width:$mobile-small) {
					font-size: 12px;
				}
			}
			p
			{
				font-size: 16px;
				line-height: 20px;
				margin-top: 10px;
				padding-right: 75px;
				color: $light-black;
				font-family: $arial;
				font-weight: $font-normal;
				@media screen and (max-width:$mobile-small) {
					font-size: 15px;
					padding-right: 0;
					margin-bottom: 0;
				}
			}
			.btn-block {
				@media screen and (max-width:$mobile-small) { 
					margin-top: 10px;
				}
			}	
			.left-content 
			{
				width: 50.31%;
				@media screen and (max-width:$mobile-small) {
					width: 100%;
				}
			}
			.image-block {
				width: 49.19%;
				@media screen and (max-width:$mobile-small) {
					width: 100%;
					padding-bottom: 56.6%;
				}
			}
			&.white-text
			{
				.left-content
				{
					padding: 64px 46px 52px 90px;
					@media screen and (max-width:$large-m) {
						padding: 30px 15px 28px 50px;
					}
					@media screen and (max-width:$mobile-small) {
						padding: 22px 29px 25px 22px;
					}
					h4 {
						color: $white;
					}
					.time-date
					{
						color: $white;
					}
					p
					{
						color: $white;
						padding-right: 0;
					}
					.btn-block
					{
						margin-top: 25px;
					}
				}
			}
			&.white-text {
				.top-title
				{
					h4 {
						color: $white;
					}
				}
				.time-date {
					color: $white;
				}
				p {
						color: $white;
				}
			}
		}
		
	}
	.news-feed-right
	{
		width: 35%;
		margin-left: 4.04%;
		margin-top: 25px;
		@media screen and (max-width:$large-m) {
			width: 37%;
			margin-left: 2.04%;
		}
		@media screen and (max-width:$ipad-landscape) {
			width: 100%;
			padding: 0;
			max-width: 330px;
		}
		@media screen and (max-width:$ipad-portrait) {
			margin-left: 0;
			max-width: none;
		}
		@media screen and (max-width:$mobile-small) {
			margin: 0;
			padding: 0 10px;
		}
		
		.recently-viewed
		{
			padding: 5px 35px 5px 30px; 
			@media screen and (max-width:$mobile-small) {
				padding: 5px 30px; 
			}
			background-color: $sky-blue-transparent;
			h4 {
				font-family: $arial;
				font-weight: $font-normal;
				color: $black;
				font-size: 18px;
				line-height: 22px;
				border-bottom: 1px solid $black;
				padding-bottom: 11px;
				margin-bottom: 0;
			}
			ul{
				padding-left: 0;
				margin-top: 0;
				li
				{
    				padding: 21px 0;
					@media screen and (max-width:$large-m) {
						padding: 15px 0;
					}
					&:not(:last-child) {
						border-bottom: 1px solid $off-white;
					}
					a {
						font-family: $arial;
						font-weight: $font-normal;
						color: $light-black;
						font-size: 16px;
						line-height: 20px;
						margin: 0;
						text-decoration: none;
						&:hover {
							color: $sky-blue;
						}
					}
				}
			}
		}
	}
}